.project-card {
	height: 150px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.project-card__link {
	display: block;
	width: fit-content;
}

.project-card__link:hover {
	color: var(--sky-600);
}

.project-card__title {
	margin: 0 0 1rem;
	font-size: 1.2rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
}

.project-card__desc {
	margin: 0;
	max-height: 45px;
	flex: 1;
}

.project-card__footer {
	display: flex;
	justify-content: space-between;
}

.project-card__avatar {
	width: 30px !important;
  height: 30px !important;
  font-size: 0.8rem !important;
}

.project-card__buttons {
	opacity: 0;
	display: flex;
	justify-content: flex-end;
  gap: 0.5rem;
}

.project-card:hover .project-card__buttons {
	opacity: 1;
}
