.project-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-page__container {
  flex: 1;
  display: flex;
  overflow: auto;
}

.project-page__column-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
}

.project-page__add-btn {
  margin-left: 1rem;
  padding-top: 1rem;
  width: 300px;
  min-height: 70px;
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
}
