.column {
	width: 300px;
}

.tasks-list {
  list-style: none;
  min-height: 0.5rem;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.task-item {
  display: block;
  margin-bottom: 1rem;
}
