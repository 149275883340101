.conf-modal__message {
  margin: 0 0 1.5rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}
  
.conf-modal__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem
}
