.header {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.header__logo {
  margin: 0 2rem 0 0;
}

.header__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.header__image {
  width: 50px;
  height: 50px;
}

.header__title {
  margin: 0;
}