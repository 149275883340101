.signin-form {
  max-width: 28rem;
  margin: 0 auto;
  z-index: 1;
}

.signin-form__content {
  padding: 2rem;
}

.signin-form__header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.signin-form__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  color: #0284c7;
}

.signin-form__desc {
  margin: 0;
  font-size: 0.875rem;
  color: #6b7280;
}

.signin-form__inputs{
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}