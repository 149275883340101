.task {
	height: 100px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
}

.task__content {
	flex: 1;
	display: flex;
	justify-content: space-between;
}

.task__title {
	margin: 0.5rem 0 1rem;
	font-size: 1rem;
	font-weight: 400;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
}

.task:hover .task__title {
	color: var(--sky-600);
}

.task__buttons {
	opacity: 0;
}

.task:hover .task__buttons {
	opacity: 1;
}

.task__priority {
	width: 100%;
	height: 3px;
}

.task__priority.task__priority--important{
	background-color: var(--lime-300);
}

.task__priority.task__priority--critical{
	background-color: var(--red-300);
}