html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

* {
  box-sizing: inherit;
}

#root {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
}
