.layout {
  width: 100%;
  padding: 1rem;
  flex: 1;
  display: flex;
  color: var(--text);
  background-color: var(--layout-bgc);
}

.layout a {
  color: inherit;
  text-decoration: inherit;
}

.layout__content {
  margin: 0 auto;
  max-width: 1400px;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
  
.layout__outlet {
  padding: 1rem 0;
  flex-grow: 1;
}

