.task-form {
  min-width: 300px;
  margin: 0 auto;
  z-index: 1;
}

.task-form__title {
  margin: 0 0 2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.task-form__inputs{
  display: flex;
  gap: 1rem;
  flex-direction: column;
}