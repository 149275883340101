.account-menu {
  margin-left: auto;
}

.account-menu__avatar {
  width: 40px !important;
  height: 40px !important;
  background-color: #337ec2 !important;
}

.account-menu__info {
  padding: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.account-menu__title {
  margin-left: 0.5rem;
  line-height: 1.5;
}

.account-menu__name {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.account-menu__email {
  margin: 0;
  font-size: 0.8rem;
}

.account-menu__nav {
  padding-top: 0.5rem;
}

.account-menu__nav-item {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--grey-600);
  cursor: pointer;
}

.account-menu__nav-item:hover {
  background-color: var(--grey-100);
}

.account-menu__nav-icon {
  margin-right: 0.5rem;
}