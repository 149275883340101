.add-title-form {
  max-width: 35rem;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.add-title-form__buttons {
  display: flex;
  gap: 1rem;
}