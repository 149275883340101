.not-found-page {
	height: 100%;
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-page__title {
	margin: 0 0 2rem;
}