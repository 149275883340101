.project-aad-btn {
	position: relative;
	height: 150px;
}

.project-aad-btn__btn {
	width: 100% !important;
	height: 100% !important;
	border-radius: 0px !important;
}

.project-aad-btn__message {
	position: absolute;
	opacity: 0;
	margin: 0 0 1rem;
	bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--sky-600);
  text-transform: uppercase;
}

.project-aad-btn__btn:hover {
	background-color: inherit !important;
}

.project-aad-btn:hover .project-aad-btn__message {
	opacity: 1;
}