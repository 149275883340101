.column-title {
  height: 70px;
  margin-bottom: 1rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-400);
}

.column-title__title {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
}

.column-title__buttons {
  display: flex;
}

.column-title__drag-handle {
  opacity: 0;
}

.column-title:hover .column-title__drag-handle {
  opacity: 1;
}